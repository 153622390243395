import React from 'react';

export default function Stars() {
  return (
    <svg
      width='121'
      height='21'
      viewBox='0 0 121 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.1104 0.0566406L13.5195 5.36434L19.6209 6.96647L15.6265 11.8489L15.9882 18.1468L10.1104 15.8566L4.2325 18.1468L4.59422 11.8489L0.599787 6.96647L6.7012 5.36434L10.1104 0.0566406Z'
        fill='#F0AD00'
      />
      <path
        d='M35.1104 0.0566406L38.5195 5.36434L44.6209 6.96647L40.6265 11.8489L40.9882 18.1468L35.1104 15.8566L29.2325 18.1468L29.5942 11.8489L25.5998 6.96647L31.7012 5.36434L35.1104 0.0566406Z'
        fill='#F0AD00'
      />
      <path
        d='M60.1104 0.0566406L63.5195 5.36434L69.6209 6.96647L65.6265 11.8489L65.9882 18.1468L60.1104 15.8566L54.2325 18.1468L54.5942 11.8489L50.5998 6.96647L56.7012 5.36434L60.1104 0.0566406Z'
        fill='#F0AD00'
      />
      <path
        d='M85.1104 0.0566406L88.5195 5.36434L94.6209 6.96647L90.6265 11.8489L90.9882 18.1468L85.1104 15.8566L79.2325 18.1468L79.5942 11.8489L75.5998 6.96647L81.7012 5.36434L85.1104 0.0566406Z'
        fill='#F0AD00'
      />
      <path
        d='M110.11 0.0566406L113.52 5.36434L119.621 6.96647L115.626 11.8489L115.988 18.1468L110.11 15.8566L104.232 18.1468L104.594 11.8489L100.6 6.96647L106.701 5.36434L110.11 0.0566406Z'
        fill='#F0AD00'
      />
    </svg>
  );
}
